<template>
  <div>
    <div>
      <h2 class="font-weight-bold black--text">
        Soal Latihan
      </h2>
      <span>Daftar semua soal latihan</span>
    </div>
    <v-row class="my-6">
      <v-col md="8">
        <v-row>
          <v-col>
            <div>
              <v-text-field
                label="Cari"
                outlined
                dense
              ></v-text-field>
            </div>
          </v-col>
          <v-col>
            <div>
              <v-autocomplete
                label="Semua"
                outlined
                dense
              ></v-autocomplete>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="mb-4">
      <h3 class="font-weight-bold mb-4">
        Pilihan Ganda ({{ exerciseMultiple.length }})
      </h3>
      <v-card
        v-for="(questionItem,index) in exerciseMultiple"
        :key="index"
        outlined
        class="mb-2"
      >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-row class="d-flex align-center">
                <v-col md="2">
                  <span>Soal No {{ questionItem.number }}</span>
                </v-col>
                <v-col md="4">
                  <v-chip
                    outlined
                    class="v-chip-light-bg success--text"
                    h-e-a-d
                    @click="checkDescription(questionItem.name)"
                  >
                    Lihat Deskripsi
                  </v-chip>
                </v-col>
                <v-col md="4">
                  <span>A.{{ questionItem.choice_1 }}</span>
                </v-col>
                <v-col md="1">
                  <!-- <a
                    href=""
                    class="text-decoration-none"
                    @click.prevent="editQuestion(questionItem.uuid)"
                  >Edit</a>
                </v-col>
                <v-col md="1">
                  <a
                    href=""
                    class="text-decoration-none"
                    @click.prevent="confirmDestroy(questionItem.uuid)"
                  >Hapus</a> -->
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>Pembahasan</p>
              {{ questionItem.discussion }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>
    <div>
    </div>
    <h3 class="font-weight-bold mb-4">
      Essay ({{ exerciseEssay.length }})
    </h3>
    <v-card
      v-for="questionItem in exerciseEssay"
      :key="questionItem.name"
      outlined
      class="mb-2"
    >
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="d-flex align-center">
            <v-row>
              <v-col md="2">
                <span>Soal No {{ questionItem.number }}</span>
              </v-col>
              <v-col md="8">
                <span>{{ questionItem.name }}</span>
              </v-col>

              <v-col md="1">
              <!-- <a
                href=""
                class="text-decoration-none"
                @click.prevent="editQuestion(questionItem.uuid)"
              >Edit</a>
            </v-col>
            <v-col md="1">
              <a
                href=""
                class="text-decoration-none"
                @click.prevent="confirmDestroy(questionItem.uuid)"
              >Hapus</a> -->
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Pembahasan</p>
            {{ questionItem.discussion }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <!-- <ModalDialog
      :visible="modalDialog"
      :width="600"
    >
      <template v-slot:header>
        Edit Pertanyaan
      </template>
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="questionEdit.number"
            label="Number"
            type="number"
            outlined
            dense
          >
          </v-text-field>
        </div>
        <div>
          <v-textarea
            v-model="questionEdit.name"
            label="Soal"
            type="text"
            outlined
            dense
          >
          </v-textarea>
        </div>
        <div class="mb-4">
          <h4 class="mb-4">
            Masukkan Gambar (jika ada)
          </h4>
          <FileInput
            v-model="initialFile"
            label="Soal Bergambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
          ></FileInput>
          <small class="d-block ms-8">File .jpg/.png, max. 3mb</small>
        </div>
        <div>
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <h4 class="mb-5 mt-8">
                Kunci Jawaban
              </h4>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_a"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        A.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_b"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        C.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_c"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        B.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_d"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        D.
                      </span>
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              class="py-0"
            >
              <h4 class="mb-4">
                Jawaban Yang Benar
              </h4>
              <div class="">
                <v-text-field
                  v-model="questionEdit.answer_key.answer"
                  label="Jawaban yang benar"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              class="py-0"
            >
              <h4 class="mb-4">
                Point Jawaban Yang Benar
              </h4>
              <div>
                <v-text-field
                  v-model="questionEdit.answer_key.point"
                  label="Point"
                  type="number"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <h4 class="mb-4">
                Pembahasan
              </h4>
              <div>
                <div>
                  <v-textarea
                    v-model="questionEdit.discussion"
                    label="Pembahasan"
                    outlined
                    dense
                  >
                  </v-textarea>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          large
          :loading="isLoadingButton"
          @click="updateQuestion(questionEdit.uuid)"
        >
          Update
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDialog = false"
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog> -->
    <ModalDialog
      :visible="modalDescription"
      :width="widthModal"
      header="Deskripsi Soal"
      :hidden-actions="false"
      @close-modal="modalDescription = false"
    >
      <template v-slot:body>
        <p v-html="detail"></p>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDescription = false"
        >
          Tutup
        </v-btn>
      </template>
    </ModalDialog>
    <!-- <ConfirmDialog
      :visible="confirmDialog"
    >
    </ConfirmDialog> -->
  </div>
</template>

<script>
import { mdiImageMultipleOutline } from '@mdi/js'
// import ConfirmDialog from '../../../components/ConfirmDialog.vue'
// import FileInput from '../../../components/FileInput.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'

export default {
  name: 'QuestionExercise',
  components: {
    ModalDialog,
    // ConfirmDialog,
    // FileInput,
  },
  data() {
    return {
      modalDescription: false,
      widthModal: 810,
      icons: {
        mdiImageMultipleOutline,
      },
      exercise: {
        title: '',
      },
      questionEdit: {
        answer_key: {
          point: 0,
          asnwer: '',
        },
      },
      questionEssay: [],
      exerciseEssay: [],
      exerciseMultiple: [],
      question: {
        answer_key: {},
      },
      answer: {},
      isLoadingButton: false,
      modalDialog: false,
      confirmDialog: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.question.image === 'string') {
          return new File([this.question.image], this.question.image, { type: 'text/plain' })
        }

        return this.question.image
      },
      set() {
        return this.question.image
      },
    },
  },
  mounted() {
    this.getExercise(this.$route.params.uuid)
  },
  methods: {
    checkDescription(data) {
      this.detail = data

      this.modalDescription = true
    },
    // getQuestion() {
    //   this.$services.ApiServices.get('exercise', this.exercise.uuid).then(
    //     ({ data }) => {
    //       this.exercise = data.data
    //     },
    //     err => console.error(err),
    //   )
    // },
    getFile(data) {
      this.question.image = data
    },
    async getExercise(uuid) {
      await this.$services.ApiServices.get('exercise', uuid).then(({ data }) => {
        this.exercise = data.data
        this.exerciseMultiple = this.exercise.question.filter(item => item.is_essay === null)
        this.exerciseMultiple.sort((a, b) => a.number - b.number)
        this.exerciseEssay = this.exercise.question.filter(item => item.is_essay)
        this.exerciseEssay.sort((a, b) => a.number - b.number)
      })
    },
    async editQuestion(uuid) {
      this.modalDialog = true
      this.question.uuid = uuid
      this.$services.ApiServices.get('question', uuid).then(({ data }) => {
        this.questionEdit = data.data
        this.questionEdit.image = data.data.image.split('/').pop()
      })
    },

    async updateQuestion(uuid) {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('number', this.questionEdit.number)
      this.formData.append('name', this.questionEdit.name)
      this.formData.append('choice_a', this.questionEdit.choice_a)
      this.formData.append('choice_b', this.questionEdit.choice_b)
      this.formData.append('choice_c', this.questionEdit.choice_c)
      this.formData.append('choice_d', this.questionEdit.choice_d)
      this.formData.append('discussion', this.questionEdit.discussion)
      this.formData.append('choice_e', 'null')
      if (typeof this.questionEdit.image !== 'string') {
        this.formData.append('thumbnail', this.questionEdit.image)
      }
      await this.$services.ApiServices.update('question', this.formData, uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )

      if (this.questionEdit.answer_key.answer !== '') {
        await this.$services.ApiServices.update(
          'answer',
          this.questionEdit.answer_key,
          this.questionEdit.answer_key.uuid,
        ).then(
          ({ data }) => {
            this.showSnackbar({
              text: data.result.message,
              color: 'success',
            })
          },
          err => console.error(err),
        )
      }
      this.isLoadingButton = false
      this.modalDialog = false
      this.getExercise(this.$route.params.uuid)
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.question.uuid = uuid
    },
    async destroyQuestion(uuid) {
      this.isLoadingButton = true
      this.question.uuid = uuid
      await this.$services.ApiServices.destroy('question', this.question.uuid).then(({ data }) => {
        this.showSnackbar({
          text: data.result.message,
          color: 'success',
        })
      })
      this.isLoadingButton = false
      this.confirmDialog = false
      this.getExercise(this.$route.params.uuid)
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
