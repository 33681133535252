<template>
  <div>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <div class="title mb-8 mt-5">
      <h2 class="font-weight-bold black--text text-capitalize">
        {{ exercise.title }}
      </h2>
    </div>
    <v-card>
      <v-tabs
        v-model="tab"
        show-arrows
        fixed-tabs
      >
        <v-tab
          v-for="tabItem in tabs"
          :key="tabItem.title"
        >
          <span>{{ tabItem.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
        class="pa-6"
      >
        <v-tab-item>
          <elearning-result-table></elearning-result-table>
        </v-tab-item>
        <v-tab-item>
          <elearning-question-exercise></elearning-question-exercise>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js'
import ElearningResultTable from './result/ElearningResultExercise.vue'
import ElearningQuestionExercise from './result/ElearningQuestionExercise.vue'

export default {
  name: 'ElearningSubjectResult',
  components: {
    ElearningResultTable,
    ElearningQuestionExercise,
  },
  data() {
    return {
      tab: '',
      tabs: [{ title: 'Hasil Latihan' }, { title: 'Soal Latihan' }],
      exercise: {},
      question: {
        answer_key: {},
      },
      answer: {},
      icons: {
        mdiArrowLeft,
      },
    }
  },
  mounted() {
    this.getExercise(this.$route.params.uuid)
  },
  methods: {
    returnBack() {
      return this.$router.back()
    },
    async getExercise(uuid) {
      await this.$services.ApiServices.get('exercise', uuid).then(({ data }) => {
        this.exercise = data.data
      })
    },
  },
}
</script>

<style>
</style>
